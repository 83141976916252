import React from 'react';
import { Link } from 'gatsby';
import mdToHtml from '../util/mdToHtml';
import mast from '../img/OffGrid.jpg';
import offGridImage from '../img/OffGrid2.png';
import formBg from '../img/ContactBG.jpg';
import duct1 from '../img/Step1.svg';
import duct2 from '../img/Step2.svg';
import duct3 from '../img/Step3.svg';
import quote from '../img/Quotes.svg';
import QuoteForm from '../components/QuoteForm';
import BotttomLogoSection from '../components/BottomLogoSection';

import '../styles/OffGridTemplate.scss';

const OffGridTemplate = (props) => {
	return (
		<main className="OffGridTemplate">
			<section className="hero is-light is-bold">
				<div
					className="hero-body"
					style={{
						backgroundImage: `url(${mast})`,
					}}
				>
					<div className="container">
						<div className="columns is-mobile">
							<div className="column is-12 is-5-tablet has-text-centered">
								<h1>
									{props.content.masthead_heading}
								</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="section offGridBlurb">
				<div className="container">
					<div className="has-text-centered">
						<h2>
							{props.content.secondary_heading}
						</h2>
						<p style={{ margin: '2rem 0' }}>
							{props.content.details_heading}
						</p>
					</div>
					<div className="columns">
						<div className="column">
							<h3>{props.content.detail_1_heading}</h3>
							<p>{props.content.detail_1_content}</p>
							<h3>{props.content.detail_2_heading}</h3>
							<p>{props.content.detail_2_content}</p>
						</div>
						<div className="column">
							<h3>{props.content.detail_3_heading}</h3>
							<p>{props.content.detail_3_content}</p>
							<h3>{props.content.detail_4_heading}</h3>
							<p>{props.content.detail_4_content}</p>
							<h3>{props.content.detail_5_heading}</h3>
							<p>{props.content.detail_5_content}</p>
						</div>
					</div>
				</div>
			</section>

			<img
				src={offGridImage}
				className="offGridImage"
				alt="Row of homes"
			/>

			<section className="is-inverted is-skewed ducts how-it-works">
				<div className="section is-medium">
					<h2 className="has-text-centered">{props.content.process_heading}</h2>
					<div className="columns is-gapless">
						<div className="column is-half">
							<img src={duct1} alt="" className="ductImage" />
						</div>
						<div className="column is-half is-flex-tablet">
							<div className="featureStep">
								<h3 class="stepText">Net-Metering:</h3>
								<p class="stepDesc">{props.content.step_1}</p>
							</div>
						</div>
					</div>
					<div className="columns is-gapless">
						<div className="column is-half">
							<img src={duct2} alt="" className="ductImage ductImage2" />
						</div>
						<div className="column is-half is-flex-tablet">
							<div className="featureStep" >
								<h3 class="stepText">Cost Efficiency:</h3>
								<p class="stepDesc">{props.content.step_2}</p>
							</div>
						</div>
					</div>
					<div className="columns is-gapless">
						<div className="column is-half">
							<img src={duct3} alt="" className="ductImage ductImage3" />
						</div>
						<div className="column is-half is-flex-tablet">
							<div className="featureStep">
								<h3 class="stepText">Battery Backup Systems:</h3>
								<p class="stepDesc">{props.content.step_3}</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div
				className="section has-text-centered testimony"
				style={{ paddingTop: '5rem', paddingBottom: '2rem' }}
			>
				<div className="container">
					<h2>{props.content.testimonials_heading}</h2>
					<div className="columns" style={{ margin: '2rem 0' }}>
						<div className="column has-text-left">
							<div className="has-text-centered">
								<img src={quote} alt="quotation" />
							</div>
							<div dangerouslySetInnerHTML={{ __html: mdToHtml(props.content.testimony_1_content) }}></div>
						</div>
						<div className="column has-text-left">
							<div className="has-text-centered">
								<img src={quote} alt="quotation" />
							</div>
							<div dangerouslySetInnerHTML={{ __html: mdToHtml(props.content.testimony_2_content) }}></div>
						</div>
					</div>
					<Link to="/faq">
						<button className="is-button">LEARN MORE</button>
					</Link>
				</div>
			</div>

			<section
				className="is-inverted is-skewed"
				style={{
					backgroundImage: `url(${formBg})`,
					backgroundPosition: 'center center',
					backgroundSize: 'cover',
				}}
			>
				<div
					className="section"
					style={{
						paddingTop: '16rem',
						paddingBottom: '12rem',
						marginBottom: '-6rem',
					}}
				>
					<div className="container">
						<div className="columns">
							<div className="column">
								<div className="contactInner">
									<h2>{props.content.contact_heading}</h2>
									<p>{props.content.contact_content}</p>
									<h3>
										<a className="phonelink" href="tel:17057952218">
											(705)&nbsp;795-2218
										</a>
									</h3>
								</div>
							</div>
							<div className="column has-text-centered">
								<div className="contactInner">
									<QuoteForm />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<BotttomLogoSection />
		</main>
	);
};

export default OffGridTemplate;
